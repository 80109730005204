import React from "react";
import {SiteMeta} from '../general/seo'
import {TopBar, BottomBar } from '../general/extremeBars'
import NavBar from '../general/navbar'
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
query($slug: String!){
    allProductsJson(filter: {slug: {eq: $slug}}) {
      nodes {
        slug
        title
        body
        images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`





export const ProductOrder = ({data}) => {  
        const PageOrder = data.allProductsJson.nodes[0];
        const seoTitle = 'Order '+PageOrder.title+' | Psaltry International Company Limited'
    return (<>
    <SiteMeta title={seoTitle} description="Official Psaltry Product Order Page"/>
    <TopBar />
    <NavBar />

    <div className="flex flex-wrap mx-8 md:mx-32 mt-20">
    <div
      role="heading"
      className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen"
    >
      {/* <span className="border-full border-4 border-sgreen p-2 rounded-full mr-4">
      <img src="./assets/images/back.png" alt="back" />
      </span> */}
     {PageOrder.title}
     <div className="border-b-4 border-sgreen w-2/12 mt-4 mb-6" />
    </div>

          {PageOrder.body.map((content, index) => {
                return(<>
                    <li role="contentinfo" className="focus:outline-none text-sm leading-6 mt-2 p-2 text-gray-600 text-justify">
                    {content}
            </li>
                </>)
            })}
    
    <div className="w-full block md:hidden">
      <div className="mx-auto" style={{width: 352, height: 437}}>
      {PageOrder.images.map((image, index)=>{
            return(
            <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="masonry"
            className="rounded-lg md:m-2 sm:m-2 m-1"
            />
            )
        })}
      </div>
    </div>
    {/* add the form here */}
    <div className="flex py-10 px-10 bg-gray-50 rounded-lg border-t-2 border-b-4 border-sgreen my-10 w-full">
    <div className="m-0 md:ml-20 w-full md:w-1/2">
            <div className="text-sgreen text-2xl">Book Order</div>
            <div className="border-b-4 border-sgreen w-3/12 mb-4" />
            {/* form wrapper */}
            <div className="py-4">
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 dark:text-gray-100 text-sm leading-tight tracking-normal mb-2"
                >
                  Full Name
                </label>
                <div className="relative">
                  <input
                    id="email2"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email2"
                  className="text-gray-800 dark:text-gray-100 text-sm leading-tight tracking-normal my-2"
                >
                  Email
                </label>
                <div className="relative">
                  <input
                    id="email2"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 text-sm leading-tight tracking-normal my-2"
                >
                  Phone Number
                </label>
                <div className="relative">
                  <input
                    id="email2"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 text-sm leading-tight tracking-normal my-2"
                >
                  Quantity needed
                </label>
                <div className="relative">
                  <input
                    id="email2"
                    type="number"
                    min={1}
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-10 flex items-center text-sm border-gray-300 rounded border"
                  />
                </div>
              </div>
              {/* Code block ends */}
              {/* Code block starts */}
              <div className="flex flex-col">
                <label
                  htmlFor="email1"
                  className="text-gray-800 text-sm leading-tight tracking-normal my-2"
                >
                  Your message
                </label>
                <div className="relative">
                  <textarea
                    id="email1"
                    className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 bg-white font-normal w-72 h-44 resize-none flex items-center text-sm border-gray-300 rounded border"
                  ></textarea>
                </div>
              </div>
              {/* Code block ends */}

              <button className="rounded-sm bg-sgreen tracking-normal text-white py-2 text-sm px-10 mt-6">
                Send
              </button>
            </div>
            
    </div>
    <div className="w-full md:md-1/2 hidden md:block flex flex-wrap">
      <div className="mx-auto my-auto mt-24" style={{width: 370, height: 437}}>
        {/* <GatsbyImage
        image={PageOrder.images[0].childImageSharp.gatsbyImageData}
        
        /> */}
        {PageOrder.images.map((image, index)=>{
            return(
            <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="masonry"
            className="rounded-lg m2"
            />
            )
        })}
      </div>
    </div>
    </div>






    </div>
    

    <BottomBar />
    </>)
}


export default ProductOrder;